import useFetch from "common/hooks/useFetch";
import { useEffect, useState } from "react";
import { parseStrapiFormat } from "../../utils/strapi";
import Select from "../Select/Select";

// filters: ['paramKey']

export type RemoteSelectProps = {
  onDataLoaded?: (value: any) => void;
  url?: string;
  filters?: any;
  sort?: string;
  populate?: any;
  processOptions?: (value: any) => void;
  additionnalOptions?: any;
  multiple?: boolean;
  comparedField?: string;
};

export function RemoteSelect({
  onDataLoaded = () => {},
  url,
  filters = [],
  sort = "",
  populate = [],
  processOptions = () => {},
  additionnalOptions = () => {},
  multiple = false,
  comparedField = "",
  ...rest
}: RemoteSelectProps) {
  const [options, setOptions] = useState<any>([]);
  const [params, setParams] = useState<any>({});

  let paramsUpdated = false;
  const newParams: any = {};

  filters.forEach((f: any) => {
    // old : newParams[f] = (rest as any)[f];
    newParams[`filters[${f.name}]`] = f.value;
    if (newParams[`filters[${f.name}]`] !== params[`filters[${f.name}]`]) {
      paramsUpdated = true;
    }
  });

  populate.forEach((p: any, index: number) => {
    newParams[`populate[${index}]`] = p;
    if (newParams[`populate[${index}]`] !== params[`populate[${index}]`]) {
      paramsUpdated = true;
    }
  });

  if (sort.length > 0) {
    newParams[`sort[0]`] = sort;
  }

  if (paramsUpdated) {
    setParams(newParams);
  }

  const getUrl = () => {
    const esc = encodeURIComponent;
    const fullparams = { ...params };
    fullparams[`pagination[limit]`] = 50000;
    fullparams[`pagination[start]`] = 0;
    const queryParams = Object.keys(fullparams)
      .filter((key) => fullparams[key] != null)
      .map((key) => `${esc(key)}=${esc(fullparams[key])}`)
      .join("&");

    return `${url}${queryParams ? "?" : ""}${queryParams}`;
  };

  const { data, isFetching }: any = useFetch(getUrl());

  useEffect(() => {
    let newData = [];

    newData = data ? parseStrapiFormat(data) : [];

    if (data && !newData.length) newData.push(data);
    if (onDataLoaded) {
      onDataLoaded(newData);
    }

    if (additionnalOptions.length && Array.isArray(newData)) {
      newData = [...additionnalOptions, ...newData];
    }

    const opts = processOptions ? processOptions(newData) : newData;

    if (JSON.stringify(options) !== JSON.stringify(opts)) {
      setOptions(opts);
    }
  }, [
    additionnalOptions,
    data,
    onDataLoaded,
    options,
    processOptions,
    setOptions,
  ]);

  return (
    <Select
      options={options}
      multiple={multiple}
      isLoading={isFetching}
      comparedField={comparedField}
      {...rest}
    />
  );
}

export default RemoteSelect;
