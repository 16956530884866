import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { NotificationCampaign } from "types/NotificationCampaign";

function NotificationCampaignItem({ item }: any) {
  const {
    title,
    description,
    numberOfTargets,
    numberOfNotificationsSent,
    status,
    path,
    filters,
    createdAt,
  } = item as NotificationCampaign;

  const { t } = useTranslation();

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full gap-4 relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <div className="flex flex-row flex-1 items-center justify-between">
        <div className="flex flex-col max-w-[80%] justify-between">
          <span className="font-bold text-primary">{title}</span>
          <span className="leading-4">{description}</span>
          <span className="mt-4">
            {t("notification.notificationPathWording")}
            {path}
          </span>
        </div>
        <div className="flex flex-col text-xs flex-1 py-2 h-full justify-between items-end">
          <span>
            {t("forms.createdAt", {
              date: dayjs(createdAt).format("lll"),
            })}
          </span>
          <div className="flex flex-col items-end">
            {(filters as any)?.users && (filters as any)?.users?.length && (
              <span>
                <span className="font-bold">{t("notification.users")} : </span>
                {(filters as any).users?.length}
              </span>
            )}
            {(filters as any)?.withAccount &&
              !(filters as any)?.users?.length && (
                <span>
                  <span className="font-bold">
                    {t("notification.withAccount")} :{" "}
                  </span>
                  {t(`forms.${(filters as any).withAccount}`)}
                </span>
              )}
            {(filters as any)?.verified &&
              (filters as any)?.withAccount !== "withoutAccount" &&
              !(filters as any)?.users?.length && (
                <span>
                  <span className="font-bold">
                    {t("notification.verified")} :{" "}
                  </span>
                  {t(`forms.${(filters as any).verified}`)}
                </span>
              )}
            {(filters as any)?.isCompany &&
              (filters as any)?.withAccount !== "withoutAccount" &&
              !(filters as any)?.users?.length && (
                <span>
                  <span className="font-bold">
                    {t("notification.isCompany")} :{" "}
                  </span>
                  {t(`forms.${(filters as any).isCompany}`)}
                </span>
              )}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 min-w-[24em] border-slate-150 gap-2">
        <div
          className={`flex justify-end italic ${
            status === "finished"
              ? "text-green-400"
              : status === "on_going"
              ? "text-blue-400"
              : ""
          }`}
        >
          {t(`notification.${status}`)}
        </div>
        <div>
          {numberOfNotificationsSent}/{numberOfTargets}{" "}
          {t("notification.notificationsSent")}
        </div>
      </div>
    </div>
  );
}

export default NotificationCampaignItem;
