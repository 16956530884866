/* eslint-disable react/no-array-index-key */
import { Constants } from "constants/Constants";
import Button from "common/components/Button/Button";
import DebounceInput from "common/components/DebounceInput/DebounceInput";
import { useTranslation } from "react-i18next";
import FileIcon from "./FileIcon";
import EmptyResult from "../EmptyResult/EmptyResult";

function FileItem({
  value,
  onChange,
  onRemoveFile,
  disabled = false,
  editMode,
}: any) {
  const { name, percentage, state, url, caption, isToRemove, ext } = value;
  const { t } = useTranslation();

  if (isToRemove) return <div style={{ width: 0 }} />;

  return (
    <div className="flex flex-col gap-2 w-full rounded-lg border py-2 px-2 bg-white border-slate-800/20">
      <div className="flex gap-1 text-sm items-center ">
        <FileIcon ext={ext} />
        <div className="flex flex-auto overflow-hidden items-center gap-1">
          <a
            href={url.includes("http") ? url : Constants.ROOT_URL}
            target="_blank"
            className="flex-1 pr-1 truncate font-bold text-slate-900"
            rel="noreferrer"
          >
            {caption || name}
          </a>
        </div>
        <div className="flex gap-1 shrink-0">
          {url && (
            <Button
              type="neutral-line"
              icon="AiOutlineDownload"
              small
              compact
              onClick={() =>
                window.open(
                  url.includes("http") ? url : Constants.ROOT_URL,
                  "_blank"
                )
              }
            />
          )}
          {state === "pending" && (
            <div className="classes.fileProgressBar">
              <div
                className="classes.fileProgress"
                style={{ width: `${percentage}%` }}
              />
            </div>
          )}
          {state !== "pending" && !disabled && onRemoveFile && (
            <Button
              type="error-line"
              icon="TrashIcon"
              onClick={() => onRemoveFile(value)}
              small
              compact
            />
          )}
        </div>
      </div>

      {editMode && (
        <div className="flex items-center gap-1">
          <DebounceInput
            type="text"
            className="w-full text-input-compact"
            placeholder={t("forms.editName")}
            value={caption}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}

function DefaultFilesList({
  onChange,
  items,
  onRemoveFile,
  disabled = false,
  editMode = false,
}: any) {
  const { t } = useTranslation();
  return items?.length ? (
    <div className="flex flex-col gap-1">
      {items.map((value: any, index: number) => (
        <FileItem
          key={`item-${index}`}
          onChange={(val: string) => {
            const newItems = [...items];
            newItems[index].caption = val;
            onChange(newItems);
          }}
          index={index}
          value={value}
          disabled={disabled}
          onRemoveFile={onRemoveFile}
          editMode={editMode}
        />
      ))}
    </div>
  ) : (
    <EmptyResult text={t("forms.noDocument")} />
  );
}

export default DefaultFilesList;
